/* *{
  border: none;
} */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #fbfbfb;
}

.header {
  width: 100vh;
}
/* Logout drop down CSS */
.toggle {
  background: transparent;
  color: #fbfbfb;
  border: none;
  font-weight: bold;
  font-size: large;
  text-transform: capitalize;
}

.toggle2 {
  background: transparent;
  color: #fbfbfb;
  border: none;
  font-weight: bold;
  font-size: large;
}

.logout {
  margin: 0.2em 0em;
  position: absolute;
  right: 3em;
  z-index: 1;
  background: #fbfbfb;
  /* height: 5.8em; */
  width: 12em;
  border-radius: 5px;
}

.logout ul,
li {
  font-weight: bold;
  list-style-type: none;
  text-align: center;
  padding: 6px;
}

.custom-li {
  padding: 8px;
  border-bottom: 2px solid #acacac;
}

/* END  */

/* Edit Process Line */
.edit-process-line {
  z-index: 1;
  top: 0.8em;
  width: 100%;
  /* left: 50%; */
  border-bottom: 1px solid #eff2f5;
  position: absolute;
  /* transform: translateX(-50%) translateY(-50%); */
}

.element-button {
  z-index: 2;
}
/* End */

.elements-text-danger > span {
  padding: 0.5em;
  margin-left: -0.1em;
}

.elements-text-safe > span {
  padding: 0.5em;
  margin-left: -0.1em;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Common Styling */
.alignSelf-center {
  align-self: center;
}

.textAlign-center {
  text-align: center;
}

.textAlign-end {
  text-align: end;
}

.color-black {
  color: black;
}

.color-white {
  color: #ecefff;
}

.empty-page {
  color: #c5c0db;
  display: flex;
  justify-content: center;
  font-size: 2rem;
  padding: 0.5em;
}

.icon-squarePen {
  color: #bcbcbc;
  font-size: x-large;
}

.icon-squarePen:hover {
  cursor: pointer;
}

.icon-squareToPen {
  color: #bcbcbc;
  font-size: x-large;
}

.icon-circleX {
  text-align: end;
}

.loading-gear {
  display: block;
  margin: 1em auto;
  width: 7em;
}

/* Main/Archive col percentage */
.width8 {
  flex: 0 0 auto;
  width: 8%;
  overflow-wrap: break-word;
}

.width9 {
  flex: 0 0 auto;
  width: 19%;
  overflow-wrap: break-word;
}

.width12 {
  flex: 0 0 auto;
  width: 12%;
  overflow-wrap: break-word;
}

.width12:hover{
  cursor: pointer;
}

.width14 {
  flex: 0 0 auto;
  width: 10%;
  overflow-wrap: break-word;
}

.width15 {
  flex: 0 0 auto;
  width: 10%;
  overflow-wrap: break-word;
}

.width17 {
  flex: 0 0 auto;
  width: 17%;
  overflow-wrap: break-word;
}

.width30 {
  flex: 0 0 auto;
  width: 30.5%;
  overflow-wrap: break-word;
}

.width32 {
  flex: 0 0 auto;
  width: 32%;
  overflow-wrap: break-word;
}

.width39 {
  flex: 0 0 auto;
  width: 39%;
  overflow-wrap: break-word;
}

.width40 {
  flex: 0 0 auto;
  width: 40%;
  overflow-wrap: break-word;
}

/* Elements folder Styling */
/* text.jsx */
.elements-text-safe {
  align-items: center;
  margin: 0.1em;
  color: #fbfbfb;
  /* font-weight: bold; */
  /* padding: 0.3em 0.5em; */
}

.elements-text-danger {
  /* margin: 0.1em; */
  color: #fbfbfb;
  /* background-color: #c01b1b; */
  /* background-color: #ff6600; */
  background-color: #EF7139;
  border-radius: 5px;
  align-items: center;
  /* font-weight: bold; */
  /* text-transform: uppercase; */
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
}

.elements-text-danger .col,
.elements-text-danger .col-1
 {
  justify-content: center;
  display: flex;
}

/* status.jsx */
.element-button-green {
  background-color: #02BD15;
  border-radius: 50%;
  height: 18px;
  padding: 0.2em 0.5em;
}

.element-button-blue {
  background-color: #4472c4;
  border-radius: 50%;
  height: 18px;
  padding: 0.2em 0.5em;
}

.element-button-red-safe {
  background-color: #ff0000;
  border-radius: 50%;
  height: 18px;
  padding: 0.2em 0.5em;
}

.element-button-red-danger {
  background-color: #FF0000;
  border-radius: 50%;
  height: 18px;
  padding: 0.2em 0.5em;
}

.element-button-grey {
  background-color: #b9b9b9;
  border-radius: 50%;
  height: 18px;
  padding: 0.2em 0.5em;
}

/* input.jsx */
.form-group {
  margin: 1em;
}

.form-group > label {
  color: #4c516b;
  font-size: 1em;
}

.form-error {
  font-weight: bold;
  color: red;
  padding: 0.3em;
  font-size: 0.8rem;
}

.form-control {
  padding: 0;
  border: none;
  border-bottom: 1px solid;
  border-color: #000000;
  background-color: #f1f1f1;
}

/* button.jsx */
.login-formik-form .custom-button > button {
  background-color: #395aff;
  border-radius: 50px;
  margin: 1em;
  color: #ffffff;
  font-weight: bold;
}

.setting-listingRow .custom-button > button {
  background-color: #eff2f5;
  border-radius: 15px;
}

.setting-listing-button .custom-button > button {
  background-color: #eff2f5;
  border-radius: 15px;
  width: 6em;
  height: 2em;
  line-height: 1em;
  font-weight: bold;
  box-shadow: none;
}

.form-check-input {
  width: 1.5em;
  height: 1.5em;
  border-radius: 10px;
  background-color: #bcbcbc;
}

/* selectSearch.jsx */
.select-search .select-search__value .select-search__input {
  height: 2em;
  width: 100%;
  border: none;
  border-radius: 5px;
  color: #1a1919;
}

.select-search .select-search__select {
  background-color: #eff2f5;
  position: absolute;
  border-radius: 20px;
}

.select-search .select-search__select .select-search__options {
  padding-left: 1em;
}

.select-search
  .select-search__select
  .select-search__options
  .select-search__row {
  padding: 0.2em;
}

.select-search
  .select-search__select
  .select-search__options
  .select-search__row
  > button {
  background-color: transparent;
  border: none;
  width: 100%;
  text-align: left;
}

/* Screen folder Styling */
/* login.jsx */
.login-background {
  background-image: url(./assets/loginBG.jpg);
  height: 100vh;
  width: 100vw;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-form {
  background-color: #f1f1f1;
  width: 50vh;
  border-radius: 20px 20px;
}

.login-form-header {
  background-color: #395aff;
  height: 40px;
}

.login-form-body {
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-logo {
  width: 150px;
  margin: 1em;
}

.login-formik-form {
  display: grid;
  font-family: "Gotham";
  justify-items: center;
}

.login-formik-form .form-group > label {
  color: #4c516b;
  font-size: 14px;
  font-weight: bolder;
}

.login-formik-form .form-group > input {
  border-bottom: 1px solid #000000;
  font-weight: bolder;
  width: 270px;
}

.login-formik-form .form-error {
  color: #ea3943;
  font-weight: 400;
  font-size: 13px;
}

.login-formik-form .custom-button > button {
  width: 220px;
  height: 50px;
  font-size: 25px;
  line-height: 24px;
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 24px;
}

.login-footer {
  color: #4c516b;
  text-align: center;
  font-family: "Gotham";
  font-size: medium;
  font-weight: bold;
  padding-bottom: 15px;
  margin: 1em;
}

.login-footer > span {
  color: #288c86;
  text-align: center;
  font-size: medium;
  font-weight: bold;
}

/* mainEdit.jsx */
.editprocess-titleRow1 {
  text-align: center;
  margin: 0em 2em;
  padding: 1em 0em;
  border-bottom: 1px solid;
  border-color: #e4e6ef;
}

.editprocess-titleRow2 {
  text-align: center;
  margin: 1em 2em;
}

.title-padding .custom-text {
  color: #aaa5be;
}

.editprocess-processRow {
  background-color: #0f1117;
  border-radius: 20px;
  margin: 0em 2em;
  padding: 2em 1em;
}

.editprocess-processRow1 {
  /* margin: 1em 2em; */
}

.editprocess-processRow2 {
  position: relative;
  /* margin: 0em 2em; */
  text-align: center;
}

.editprocess-processRow3 {
  margin: 0em 2em;
  padding: 1em 0em;
  color: white;
  text-align: center;
  border-bottom: 1px solid;
  border-color: #e4e6ef;
  font-size: small;
}

/* Common folder Styling */
/* header.jsx */
.header-main {
  z-index: 1;
  background-color: #101e46;
  padding: 0em 5em;
}

.header-profilePic {
  height: 60px;
  width: 60px;
  border-radius: 40px;
  margin: 1em 1em 0em 2em;
}

.header-tabButton .custom-plainButton > button {
  font-size: larger;
  color: white;
  background-color: transparent;
  border: none;
  margin: 0em 0.3em;
  padding: 1.5em 1em;
  font-weight: bold;
}

.header-tabButtonActive .custom-plainButton > button {
  font-size: larger;
  color: white;
  background-color: transparent;
  border: none;
  margin: 0em 0.3em;
  padding: 1.5em 1em;
  font-weight: bold;
  border-bottom: 5px solid white;
  padding-bottom: 0.2em;
}

.header-tabButton .custom-plainButton > button:hover {
  border-bottom: 5px solid gainsboro;
  padding-bottom: 0.2em;
}

.header-selection {
  width: 8.5em;
}

/* .header-tabButton .custom-plainButton > button:focus {
  border-bottom: 5px solid white;
  padding-bottom: 0.2em;
} */

.header-userInfo-colPadding.col {
  padding: 2em 2em;
  position: relative;
  justify-content: end;
  padding-bottom: 0em;
}

.header-userInfo .custom-text {
  color: #fafafa;
  font-size: 20px;
  font-weight: bold;
}

.header-companyInfo1 .custom-text {
  color: #fbfbfb;
  font-size: x-large;
  font-weight: bold;
}

.header-companyInfo2 .custom-text {
  color: #aaa5be;
  font-size: 15px;
}

.header-funcButton-paddingTop {
  padding-top: 0.5em;
}

.header-funcButton-qsAlign {
  margin: 20px;
  color: #a0a0a0;
  background-color: #ececec;
  /* padding: 0.35em; */
  border-radius: 0.65em;

  display: inline-flex;
}

.header-funcInput-qs {
  color: #a0a0a0;
  background-color: transparent;
  /* box-shadow: inset 0px 1px 1px 1px rgb(34 34 34 / 50%); */
  border: none;
  border-radius: 0.5em 0 0 0.5em;
  text-align: center;
  height: 35px;
  width: 15vh;
  font-size: medium;
  font-weight: bold;
}

.header-funcButton-qs {
  border: none;
  border-radius: 0 0.5em 0.5em 0;
  color: white;
  background-color: #395aff;
  font-size: medium;
  font-weight: bold;
}

.header-funcButton-qsAlign > input:focus {
  outline: none;
  background-color: white;
}

.header-funcButton-add .custom-plainButton > button {
  color: #ffffff;
  background-color: #395aff;
  border: none;
  border-radius: 10px;
  text-align: center;
  margin: 0px 10px;
  height: 35px;
  padding: 0px 15px 0px 15px;
  font-weight: bold;
  font-size: medium;
}

.header-funcButton .custom-plainButton > button {
  color: #81799e;
  background-color: #f1f7fb;
  border: none;
  border-radius: 10px;
  text-align: center;
  margin: 0px 10px;
  height: 35px;
  padding: 0px 15px 0px 15px;
  font-weight: bold;
  font-size: large;
  box-shadow: none;
}

.header-funcButton .custom-plainButton > button:hover {
  background-color: #395aff;
  color: #ffffff;
}

/* template.jsx && titlejob.jsx && titleProcess.jsx */
.body-titleRow {
  font-size: medium;
  border-bottom: 1px solid;
  border-color: #e4e6ef;
  padding: 0.5rem 0.7em;
  margin: 0em;
}

.body-titleRow-col1 {
  /* display: grid; */
  /* align-items: center; */
}

.body-titleRow-text .custom-text {
  color: #fbfbfb;
}

.title-padding {
  padding: 0;
  font-weight: bold;
  text-align: center;
  /* line-height: 1rem; */
}

.title-custom-padding {
  padding: 0;
  font-weight: bold;
  text-align: center;
  /* line-height: 1rem; */
  white-space: nowrap;
}

.title-custom-padding .custom-text {
  color: #fbfbfb;
}

.title-process {
  color: #fbfbfb;
  width: 100%;
  font-size: 0.875em;
  font-weight: bold;
  border: 0px solid transparent;
  /* overflow: hidden;
  text-overflow: ellipsis; */
  margin: 0px;
}

.title-process tr,
td {
  line-height: 1em;
  padding: 0px;
  /* word-break: keep-all; */
}

.button-status-process {
  max-width: 8em;
}

.button-status-process-min {
  max-width: 2em;
}

.edit-title-padding .elements-text-safe {
  font-size: large;
  font-weight: bold;
}
.title-process-top {
  vertical-align: top;
}

.title-process-bottom {
  vertical-align: bottom;
}

/* .row .col-1 {
  padding: 0px 5px 0px 30px;
} */

/* jobListing.jsx */
.body-joblist-container {
  padding: 0;
}

.body-joblistRow {
  font-size: 14px;
  border-bottom: 1px solid;
  border-color: #e4e6ef;
  padding: 0.5em 0em 0.5em 0em;
  margin: 0em;
}

.body-joblist-jobname {
  overflow-wrap: break-word;
  padding: 0em 0em 0em 0em;
  width: 100px;
}

.body-joblist-jobname-company.col {
  width: 100px;
  padding: 0em 2em 0em 2em;
}

/* jobListTimeline.jsx */
.body-joblist-timeline {
  padding: 0.5em;
}

.body-joblist-timeline-text {
  color: #fbfbfb;
  font-size: small;
}

/* settingListing.jsx */
.setting-titleRow {
  border-bottom: 2px solid;
  border-color: #e4e6ef;
  margin: 1em 1em;
  padding: 0em 0em 1em 0em;
  color: #fbfbfb;
  font-weight: bold;
}

.setting-listingRowGreen {
  margin: 1em 1em;
  border-left: 3px solid #50cd89;
}
.setting-listingRowBlue {
  margin: 1em 1em;
  border-left: 3px solid #009ef7;
}
.setting-listingRowYellow {
  margin: 1em 1em;
  border-left: 3px solid #ffc700;
}
.setting-listingRowRed {
  margin: 1em 1em;
  border-left: 3px solid #ff3131;
}

.setting-listing-text1 .custom-text {
  color: #fbfbfb;
  font-weight: bold;
  font-size: 18px;
  margin-top: 0em;
}

.setting-listing-text2 .custom-text {
  color: #e4e6ef;
}

.setting-listing-col3 {
  align-self: center;
}

.setting-listing-button {
  margin: 0em 0em 0em 3em;
}

/* settingModalBox.jsx */
.modalbox-cebox-row {
  padding: 0.5em 0em;
}

/* editProcessTab.jsx */
.body-editprocessTab {
  margin: 0em 2em;
  padding: 1em 0em;
}
.body-editprocessTab ul,
li {
  padding: 0em;
}

/* Edit Page Tab */
.editprocessTab-Tabs {
  border: none;
  justify-content: center;
}

/* editprocessTab-Tabs */
.editprocessTab-Tabs .nav-item .nav-link.active {
  border: none;
  color: #395aff;
  font-weight: bold;
  background-color: transparent;
}

.nav-fill .nav-link {
  /* padding: 0.5rem 3.5rem; */
  border-radius: 10px;
  color: #f4f4f4;
}

.nav-tabs .nav-link {
  border: none;
}

/* Tab End */

/* editProcessTabEdit.jsx */
.editprocessTabcontent-container {
  background-color: #0f1117;
  border-radius: 10px;
  margin: 1em 0em;
}

.editprocessTabEdit-row1 {
  text-align: center;
  padding: 1em 0em;
}

/* editProcessTabEditForm.jsx */
.editprocessTabEditForm-container {
  background-color: #0f1117;
  border-radius: 10px;
  margin: 1em 0em;
  padding: 1rem 5rem;
}

.editprocessTabEditForm-container .row {
  font-size: medium;
  font-weight: bold;
  margin-top: 0.5rem;
  /* padding: 0em 2em; */
  color: #aaa5be;
}

.editprocessTabEditForm-title > select {
  font-size: medium;
  height: 2em;
  color: #1a1919;
  width: 100%;
}

.editprocessTabEditForm-title > select:focus {
  font-size: medium;
  height: 2em;
  color: black;
}

.editprocessTabEditForm-title .custom-text > input {
  font-size: medium;
  height: 2em;
  padding: 1rem;
  width: 100%;
  color: #1a1919;
}

.editprocessTabEditForm-title .custom-text > input:focus {
  color: black;
}

.editprocessTabEditForm-title > textarea {
  font-size: medium;
  color: #1a1919;
  width: 100%;
}

.editprocessTabEditForm-title > textarea:focus {
  color: black;
}

.editprocessTabEditForm-link {
  /* color: #0d6efd; */
  /* text-decoration: underline; */
  color: whitesmoke;
  font-size: 0.9rem;
}

.editprocessTabEditForm-container .row .custom-text {
  padding: 0rem;
}

.editprocessTabEditForm-container .row .react-datetime-picker {
  color: #1a1919;
  height: 2rem;
  border-radius: 5em;
  padding: 0px;
  background: transparent;
  font-weight: normal;
  z-index: 3;

}

.editprocessTabEditForm-container .react-datetime-picker__wrapper {
  border: none;
  background: #fbfbfb;
  border-radius: 0.3rem;
  padding-left: 1em;
}

.react-datetime-picker__inputGroup {
  color: #1a1919;
}

.react-datetime-picker__inputGroup__input {
  color: #1a1919;
}

.editprocessTabEdit-col1 {
  text-align: end;
  color: #aaa5be;
  font-weight: bold;
  padding: 0.5rem 0rem;
}

.editprocessTabEdit-col1-attachment {
  font-size: smaller;
  text-align: end;
  color: #aaa5be;
  font-weight: bold;
  padding: 0.5rem 0rem;
}

.editprocessTabEdit-col2 {
  color: #fbfbfb;
  padding: 0.5em 0.5em;
  /* text-transform: capitalize; */
}

.uploadfile-preview {
  width: 100px;
  height: 100px;
}

/* editProcessTabRemark.jsx */
.editprocessTabRemark-row1 {
  border-bottom: 1px solid;
  border-color: #aaa5be;
  color: #aaa5be;
  font-weight: 500;
  justify-content: center;
  padding: 0.5em 0.5em;
  margin: 1em;
  /* text-transform: capitalize; */
}

.editprocessTabRemark-row2 {
  color: #fbfbfb;
  justify-content: center;
  /* padding: 1em 0em; */
}

/* editProcessTabView.jsx */
.editprocessTabView-col1 {
  text-align: end;
  color: #aaa5be;
  font-weight: bold;
  padding: 0.5rem 0rem;
}

.editprocessTabView-col2 {
  color: #fbfbfb;
  padding: 0.5em 0.5em;
  /* text-transform: capitalize; */
}

.editprocessTabView > span {
  color: #fbfbfb;
}

/* editProcessTabPurchase.jsx */
.editprocessTabPurchase-row1 {
  border-bottom: 1px solid;
  border-color: #e4e6ef;
  padding: 1em 0em;
  margin: 0em 1em;
}

.editprocessTabPurchase-col {
  text-align: center;
  color: #fbfbfb;
}

.editprocessTabPurchase-row2 {
  border-bottom: 1px solid;
  border-color: #e4e6ef;
  padding: 0.3em 0em;
  margin: 0em 1em;
}

.editprocessTabcontent-container {
  padding: 1rem;
  font-size: 1em;
}

/* Edit Page Button */
.editprocessTabEdit-row1 .custom-plainButton > button {
  background-color: #395aff99;
  color: #e4e6ef;
  border: none;
  width: 6em;
  border-radius: 10px;
  font-weight: bold;
  height: 2em;
  box-shadow: none;
}

/* editProcessTabPurchaseEdit.jsx */
.purchaseEdit-name {
  color: #aaa5be;
  font-weight: bold;
  margin: 1em 2em 0em 2em;
}

.purchaseEdit-input {
  margin: 0em 1em;
}

.purchaseEdit-input .custom-text > input {
  font-size: medium;
  height: 2em;
  padding: 1rem;
  width: 100%;
}

.purchaseEdit-input .custom-text > input {
  font-size: medium;
  height: 2em;
  padding: 1rem;
  width: 100%;
  color: black;
}

.purchaseEdit-input2 {
  margin: 0em 2em;
}

.purchaseEdit-buttonRow {
  margin: 2em;
  text-align: center;
}

.purchaseEdit-container {
  display: flex;
  justify-content: center;
}

/* Popup folder Styling */
/* modalbox.jsx */
.popup-modalbox {
  background-color: #1d1d20;
  border-radius: 30px;
  border: none;
}

.modalbox-Xbutton-div {
  align-self: flex-end;
  padding: 0.5em 0.5em;
}

.modalbox-Xbutton {
  width: 35px;
  height: 35px;
  background-color: #a7a3bf;
  border-radius: 50%;
  color: #0f1117;
  font-size: 17px;
  font-weight: bold;
}

.modalbox-header {
  border: none;
  color: #fbfbfb;
  padding: 0em 3em;
}

.modalbox-header-sure {
  padding: 2em 2em;
  border: none;
  color: #fbfbfb;
  align-self: center;
}

.modalbox-header-createdit {
  border: none;
  color: #fbfbfb;
  padding: 0em 1em;
  text-transform: capitalize;
}
.modalbox-header-createdit .search{
  position: absolute;
  right: 0;
  padding: 2em;
}
.modalbox-header-createdit .search .search-input{
    background-color: transparent;
    border: 1px solid white;
    border-radius: 0.5em;
    font-size: small;
    padding: 0.5em;
    color: #fbfbfb;
}
.popup-modalbox-bg {
  background: rgba(255, 255, 255, 0.2);
}

/* listBox.jsx */
.modalbox-listbox-title {
  text-align: center;
}

.modalbox-listbox-title-row {
  border-bottom: 1px solid;
  border-color: #e4e6ef;
}

.modalbox-listbox-title-col .custom-text {
  color: #fbfbfb;
  font-weight: bold;
}

.modalbox-listbox-listing-row {
  border-bottom: 1px solid;
  border-color: #e4e6ef;
  align-items: center;
}

.modalbox-listbox-listing-col .custom-text {
  color: #fbfbfb;
}

.modalbox-listbox-listing-button .custom-button {
  background-color: #eff2f5;
  border-radius: 15px;
  box-shadow: none;
}

/* ruSure.jsx */
.modalbox-rusure-row {
  margin: 1em 5em;
  text-align: center;
}

.modalbox-rusure-button .custom-button > button {
  background-color: #eff2f5;
  color: #313036;
  border: none;
  height: 2.5em;
  width: 100%;
  font-size: large;
  border-radius: 10px;
  font-weight: bold;
  line-height: 0rem;
  box-shadow: none;
}

.modalbox-rusure-blue-button .custom-button > button {
  background-color: #395aff99;
  color: #eff2f5;
  border: none;
  height: 2.5em;
  width: 100%;
  font-size: large;
  border-radius: 10px;
  font-weight: bold;
  line-height: 0rem;
  box-shadow: none;
}

/* creatEditBox.jsx */
.modalbox-cebox-listName {
  text-align: end;
  color: #aaa5be;
  /* padding-right: 30px; */
  font-weight: bold;
}

.modalbox-cebox-list .custom-text > input {
  font-size: medium;
  height: 2em;
  color: #1a1919;
  width: 100%;
}

.modalbox-cebox-list .custom-text > input:focus {
  color: black;
}

.modalbox-cebox-list .custom-text > textarea {
  font-size: medium;
  color: #1a1919;
  width: 100%;
}

.modalbox-cebox-listInput {
  color: #eff2f5;
  border-bottom: 1px solid;
  border-color: #e4e6ef;
}

.modalbox-cebox-nextJobNo {
  font-size: medium;
  height: 2em;
  color: #1a1919;
  width: 100%;
  border: none;
  border-radius: 5px;
  background-color: white;
}

/* Form Input Setting Page */
.modalbox-cebox-input .custom-text > input {
  background: transparent;
  color: #eff2f5;
  border-bottom: 0.1em solid #eff2f5;
}

.modalbox-cebox-input .custom-text > select {
  background: #eff2f5;
  color: #eff2f5;
  border-bottom: 0.1em solid #eff2f5;
}

.modalbox-cebox-inputBG {
  background: none;
  border: none;
}

/*Modalbox Operation ID Listing */
.modalbox-cebox-title {
  text-transform: capitalize;
  border-bottom: 1px solid #eff2f5;
  padding: 0.5em 0em;
}

.modalbox-cebox-content {
  /* text-align: center; */
  border-bottom: 1px solid #eff2f5;
  padding: 0.5em 0em;
}

/* Setting View */
.modalbox-body {
  color: #eff2f5;
}

/* jobBox.jsx */
select {
  height: 25px;
  width: 210px;
  border: none;
  border-radius: 5px;
}

.custom-text .plainText {
  height: 25px;
  width: 210px;
  border: none;
  border-radius: 5px;
}

/* Remark TextArea */
textarea {
  width: 210px;
  height: 178px;
  border: none;
  border-radius: 5px;
}

/* DateTime Picker */
.modalbox-jobox-datetime {
  background: #f4f4f4;
  border: none;
}

.react-datetime-picker {
  border: none;
  border-radius: 0.5rem;
  width: 100%;
}

.modalbox-jobox-datetime .react-datetime-picker__wrapper {
  border: none;
}

.modalbox-jobox-datetime .react-datetime-picker__calendar-button {
  background: #395aff;
  border: none;
  border-radius: 0.2rem;
}

.modalbox-jobox-datetime
  .react-datetime-picker__calendar-button:disabled
  .react-datetime-picker__button__icon {
  stroke: white;
}

.react-datetime-picker__button svg {
  stroke: white;
}
/* .react-datetime-picker__button .react-datetime-picker__button__icon,
.react-datetime-picker__button:enabled:focus
  .react-datetime-picker__button__icon {
  color: #f4f4f4;
} */

/* .modalbox-jobox-datetime .react-datetime-picker__button {
  padding: 4px 3px;
} */

.modalbox-jobox-row {
  border: none;
  margin: 1em;
}

/* jobBoxListing.jsx Add New Job Button */
.modalbox-cebox-buttonRow {
  text-align: center;
  align-items: center;
  padding: 1em 12em;
}

.editmodalbox-cebox-buttonRow {
  text-align: center;
  align-items: center;
  padding: 1em 2em;
}

.modalbox-cebox-button .custom-plainButton > button {
  background-color: #eff2f5;
  border: none;
  height: 2.5em;
  width: 100%;
  border-radius: 10px;
  font-weight: bold;
  text-transform: capitalize;
  box-shadow: none;
}

.modalbox-cebox-button .custom-plainButton > button:hover {
  background-color: #395aff99;
  color: #eff2f5;
}

.modalbox-cebox-button .activeButton .custom-plainButton > button {
  background-color: lime;
  color: black;
  border: none;
  height: 2.5em;
  width: 100%;
  border-radius: 10px;
  font-weight: bold;
  text-transform: capitalize;
  box-shadow: none;
}

.modalbox-cebox-button .activeButton .custom-plainButton > button:hover {
  background-color: red;
}

.modalbox-cebox-button .inactiveButton .custom-plainButton > button {
  background-color: red;
  color: black;
  border: none;
  height: 2.5em;
  width: 100%;
  border-radius: 10px;
  font-weight: bold;
  text-transform: capitalize;
  box-shadow: none;
}

.modalbox-cebox-button .inactiveButton .custom-plainButton > button:hover {
  background-color: lime;
}

/* Edit Form btn */
.editForm-cebox-button-submit .custom-button > button {
  background-color: #eff2f5;
  border: none;
  height: 2em;
  border-radius: 10px;
  font-weight: bold;
  line-height: 0rem;
  box-shadow: none;
}

.editForm-cebox-button-submit .custom-button > button:hover {
  background-color: #395aff99;
  color: #eff2f5;
  box-shadow: none;
}

.editmodalbox-cebox-buttonRow .col .custom-plainButton > button {
  background-color: #eff2f5;
  border: none;
  height: 2em;
  width: 5em;
  border-radius: 10px;
  font-weight: bold;
  line-height: 0rem;
  margin: 0;
  box-shadow: none;
}

.editmodalbox-cebox-buttonRow .col .custom-plainButton > button:hover {
  background-color: #395aff99;
  color: #eff2f5;
}

/* Purchasing btn Edit Form */
.editForm-cebox-button-submit .custom-plainButton > button {
  background-color: #395aff99;
  color: #eff2f5;
  border: none;
  height: 2em;
  width: 10rem;
  border-radius: 10px;
  font-weight: bold;
  line-height: 0rem;
  box-shadow: none;
}

.editprocessTabPurchase-col .custom-plainButton > button {
  background-color: #395aff99;
  color: #eff2f5;
  border: none;
  height: 2em;
  width: 5rem;
  border-radius: 10px;
  font-weight: bold;
  line-height: 0rem;
  box-shadow: none;
}

.editForm-cebox-button-submit .custom-plainButton {
  text-align: center;
}

/* Edit Form btn*/
.editForm-cebox-button {
  background-color: #eff2f5;
  border: none;
  height: 2em;
  width: 100%;
  border-radius: 10px;
  font-weight: bold;
  line-height: 0rem;
}

.modalbox-cebox-button .custom-button > button {
  background-color: #eff2f5;
  border: none;
  height: 2.5em;
  width: 100%;
  border-radius: 10px;
  font-weight: bold;
  line-height: 0rem;
  box-shadow: none;
}

.modalbox-cebox-button .custom-button > button:hover {
  background-color: #395aff99;
  color: #eff2f5;
}

.modalbox-cebox-button .custom-button > button:focus {
  box-shadow: none;
  background-color: #395aff99;
  color: #eff2f5;
}

.normal-btn {
  background-color: #eff2f5;
  color: #313036;
  border: none;
  height: 2em;
  width: 6.5em;
  border-radius: 10px;
  font-weight: bold;
  line-height: 0rem;
}

.normal-btn:hover {
  background-color: #395aff99;
  color: #eff2f5;
}

.normal-btn:focus {
  box-shadow: none;
  background-color: #395aff99;
  color: #eff2f5;
}

.blue-btn .custom-button > button {
  background-color: #eff2f5;
  color: #313036;
  border: none;
  height: 2em;
  width: 6.5em;
  border-radius: 10px;
  font-weight: bold;
  line-height: 0rem;
  box-shadow: none;
}

.blue-btn .custom-button > button:hover {
  background-color: #395aff99;
  color: #eff2f5;
}

.blue-btn .custom-button > button:focus {
  box-shadow: none;
  background-color: #395aff99;
  color: #eff2f5;
}

.blue-btn2 .custom-button > button {
  background-color: #eff2f5;
  color: #313036;
  border: none;
  height: 2em;
  width: 8em;
  border-radius: 10px;
  font-weight: bold;
  line-height: 0rem;
  box-shadow: none;
}

.blue-btn2 .custom-button > button:hover {
  background-color: #395aff99;
  color: #eff2f5;
}

.blue-btn2 .custom-button > button:focus {
  box-shadow: none;
  background-color: #395aff99;
  color: #eff2f5;
}

.table > :not(caption) > * > * {
  padding: 0em;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}

td {
  white-space: nowrap;
}

/* Are u sure page */
.popup-modalbox-sure {
  /* height: 15em; */
  background: rgba(255, 255, 255, 0.2);
  border-radius: 30px;
  border: 1px solid white;
}

.popup-modalbox-gear {
  background: rgba(255, 255, 255, 0.2);
}

.popup-modalbox-gearHead {
  color: white;
  display: flex;
  justify-content: center;
  font-size: x-large;
  font-weight: bold;
  border: none;
}

.popup-modalbox-gearBody {
  padding: 0em;
}

.modal-content {
  /* padding: 1em; */
  background-color: #1d1d20;
  border-radius: 30px;
}

.backtoask {
  --width: 300px;
  --button-color: #ffffff;
  --height: 100px;
  --border-radius: 17px;
}

@media screen and (max-width: 1760px) {
  .button-status-process {
    max-width: 6em;
  }
}

@media screen and (max-width: 1700px) {
  .button-status-process {
    max-width: 5em;
  }
}

@media screen and (max-width: 1630px) {
  .body-titleRow .col-6 {
    padding-left: 0px;
  }

  .body-joblistRow .textAlign-center {
    padding-left: 0px;
    padding-right: 0px;
  }

  .button-status-process2 {
    max-width: 8em;
  }
}

@media screen and (max-width: 993px) {
  .modalbox-cebox-buttonRow {
    padding: 1em 5em;
  }
}
